import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { updateValues } from '../../redux/modules/filters';
import { loadPayments } from '../../redux/modules/payments';
import DateTimePicker from '../../components/DateTimePicker/DateTimePicker';
import { Report, Transaction } from '../../models';
import Icon from '@mdi/react';
import {
    mdiBriefcaseAccountOutline,
    mdiBank,
    mdiDomain,
    mdiCreditCardOutline,
    mdiPassportBiometric,
    mdiCalendar,
    mdiFormatHorizontalAlignLeft,
    mdiFormatHorizontalAlignRight,
    mdiCash,
    mdiCheck,
    mdiCashMultiple
} from '@mdi/js';

import _ from 'lodash';
import moment from 'moment';

import './FiltersForm.css';

let FiltersForm = ({ askForPayment }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);

    // Get stored values from global filters state
    const values = useSelector(state => state.filters.values);

    // use them as default state values
    const [ filters, setFilters ] = useState(values);

    useEffect(() => {
        if (!values.from && !values.to) {
            const new_values =  {
                ...values,
                from: moment().startOf('day').toISOString(),
                to: moment().endOf('day').toISOString()
            };
            setFilters(new_values);
            dispatch(updateValues(new_values));
        }
        dispatch(loadPayments());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // on values change, sync fields
    const changed = (property, value) => {
        let f = _.clone(filters);

        f[property] = value;

        if (property === 'payment_system_id') {
            f.acquirer_id = "";
            f.device_id = "";
        }

        if (!f.account_id)
            f.device_id = "";
        if (!f.payment_system_id)
            f.acquirer_id = "";

        if (property === 'payment_status' && (value === Transaction.PAYMENT_STATUS_UNPAID || value === 'all')) {
            f.payment_id = "";
        }

        setFilters(f);
    }

    const submit = (e) => {
        // save in global filters state
        dispatch(updateValues(filters));

        if (askForPayment) {
            const {
                account_id,
                to,
                payment_system_id,
                acquirer_id
            } = filters;
            askForPayment({
                account_id,
                to,
                payment_system_id,
                acquirer_id
            });
        }
    };

    const { payment_systems } = useSelector((state) => state.payment_systems);
    const { acquirers }       = useSelector((state) => state.acquirers);
    const { card_types }      = useSelector((state) => state.card_types);
    const { payments }        = useSelector((state) => state.payments);

    let { devices }  = useSelector((state) => state.devices);
    let { accounts } = useSelector((state) => state.accounts);
    if (!user.isSuperAdmin()) {
        devices  = (devices || []).filter(d => d.account_id === user.account_id);
        accounts = (accounts || []).filter(a => a.id === user.account_id);
    }

    if (askForPayment)
        return (
            <Form className="FiltersForm">
                <Row>
                    <Col sm={6}>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <span className="input-group-text">
                                    <Icon path={mdiBriefcaseAccountOutline} size={1} color="grey" />
                                </span>
                            </InputGroupAddon>
                            <Input
                                type="select"
                                className="custom-select"
                                onChange={(e) => changed('account_id', e.target.value)}
                                value={filters.account_id}
                            >
                                <option key="0" value="">---</option>
                                {accounts && accounts.map((a) =>
                                    <option key={a.id} value={a.id}>{a.name}</option>
                                )}
                            </Input>
                        </InputGroup>
                    </Col>
                    <Col sm={6}>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <span className="input-group-text">
                                    <Icon path={mdiFormatHorizontalAlignRight} size={1} color="grey" />
                                    <Icon path={mdiCalendar} size={1} color="grey" />
                                </span>
                            </InputGroupAddon>
                            <Field
                                component={DateTimePicker}
                                onChange={(date) => changed('to', (date || moment().add(1, 'month')).endOf('day').toISOString())}
                                type="text"
                                name="to"
                                timeFormat={true}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <span className="input-group-text">
                                    <Icon path={mdiBank} size={1} color="grey" />
                                </span>
                            </InputGroupAddon>
                            <Input
                                type="select"
                                className="custom-select"
                                onChange={(e) => changed('payment_system_id', e.target.value)}
                                value={filters.payment_system_id}
                            >
                                <option key="0" value="">---</option>
                                {payment_systems && payment_systems.map((p) =>
                                    <option key={p.id} value={p.id}>{p.name}</option>
                                )}
                            </Input>
                        </InputGroup>
                    </Col>
                    <Col sm={6}>
                        {filters.payment_system_id && (
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <span className="input-group-text">
                                    <Icon path={mdiDomain} size={1} color="grey" />
                                </span>
                            </InputGroupAddon>
                            <Input
                                type="select"
                                className="custom-select"
                                onChange={(e) => changed('acquirer_id', e.target.value)}
                                value={filters.acquirer_id}
                            >
                                <option key="0" value="">---</option>
                                {acquirers && acquirers.filter(a => a.payment_system_id === filters.payment_system_id).map((a) =>
                                    <option key={a.id} value={a.id}>{a.name}</option>
                                )}
                            </Input>
                        </InputGroup>
                        )}
                    </Col>
                </Row>
                <Row className="btn-row">
                    <Col sm="12" md={{ size: 4, offset: 4 }}>
                        <Button
                            type="button"
                            block
                            color="info"
                            onClick={submit}
                            disabled={ !filters.account_id }
                        >{ t('common.validate') }</Button>
                    </Col>
                </Row>
            </Form>
        );

    return (
        <Form className="FiltersForm">
            <Row>
                <Col sm={6} md={4} lg={3}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <span className="input-group-text">
                                <Icon path={mdiBriefcaseAccountOutline} size={1} color="grey" />
                            </span>
                        </InputGroupAddon>
                        <Input
                            type="select"
                            className="custom-select"
                            onChange={(e) => changed('account_id', e.target.value)}
                            value={filters.account_id}
                        >
                            <option key="0" value="">---</option>
                            {accounts && accounts.map((a) =>
                                <option key={a.id} value={a.id}>{a.name}</option>
                            )}
                        </Input>
                    </InputGroup>
                </Col>
                <Col sm={6} md={4} lg={3}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <span className="input-group-text">
                                <Icon path={mdiCalendar} size={1} color="grey" />
                                <Icon path={mdiFormatHorizontalAlignLeft} size={1} color="grey" />
                            </span>
                        </InputGroupAddon>
                        <Field
                            component={DateTimePicker}
                            onChange={(date) => changed('from', (date || moment()).startOf('day').toISOString())}
                            type="text"
                            name="from"
                            timeFormat={true}
                        />
                    </InputGroup>
                </Col>
                <Col sm={6} md={4} lg={3}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <span className="input-group-text">
                                <Icon path={mdiFormatHorizontalAlignRight} size={1} color="grey" />
                                <Icon path={mdiCalendar} size={1} color="grey" />
                            </span>
                        </InputGroupAddon>
                        <Field
                            component={DateTimePicker}
                            onChange={(date) => changed('to', (date || moment().add(1, 'month')).endOf('day').toISOString())}
                            type="text"
                            name="to"
                            timeFormat={true}
                        />
                    </InputGroup>
                </Col>
                <Col sm={6} md={4} lg={3}>
                    <Row>
                        <Col sm={6}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                        <Icon path={mdiCash} size={1} color="grey" />
                                    </span>
                                </InputGroupAddon>
                                <Input
                                    type="select"
                                    className="custom-select"
                                    onChange={(e) => changed('type', e.target.value)}
                                    value={filters.type}
                                >
                                    <option key="0" value="">---</option>
                                    {Transaction.types().map((type) =>
                                        <option key={type} value={type}>
                                            { t(`transactions.types.${type}`) }
                                        </option>
                                    )}
                                </Input>
                            </InputGroup>
                        </Col>
                        <Col sm={6}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                        <Icon path={mdiCheck} size={1} color="grey" />
                                    </span>
                                </InputGroupAddon>
                                <Input
                                    type="select"
                                    className="custom-select"
                                    onChange={(e) => changed('status', e.target.value)}
                                    value={filters.status}
                                >
                                    <option key="0" value="">---</option>
                                    {Transaction.status().map((status) =>
                                        <option key={status} value={status}>
                                            { t(`transactions.statuses.${status}`) }
                                        </option>
                                    )}
                                </Input>
                            </InputGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col sm={6} md={4} lg={3}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <span className="input-group-text">
                                <Icon path={mdiPassportBiometric} size={1} color="grey" />
                            </span>
                        </InputGroupAddon>
                        <Input
                            type="select"
                            className="custom-select"
                            onChange={(e) => changed('device_id', e.target.value)}
                            value={filters.device_id}
                        >
                            <option key="0" value="">---</option>
                            {devices && devices.filter((d) => d.account_id === filters.account_id && (!filters.payment_system_id || filters.payment_system_id === d.payment_system_id)).map((d) =>
                                <option key={d.id} value={d.id}>{d.name} ({d.identifier})</option>
                            )}
                        </Input>
                    </InputGroup>
                </Col>
                <Col sm={6} md={4} lg={3}>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <span className="input-group-text">
                                <Icon path={mdiBank} size={1} color="grey" />
                            </span>
                        </InputGroupAddon>
                        <Input
                            type="select"
                            className="custom-select"
                            onChange={(e) => changed('payment_system_id', e.target.value)}
                            value={filters.payment_system_id}
                        >
                            <option key="0" value="">---</option>
                            {payment_systems && payment_systems.map((p) =>
                                <option key={p.id} value={p.id}>{p.name}</option>
                            )}
                        </Input>
                    </InputGroup>
                </Col>
                <Col sm={6} md={4} lg={3}>
                    <Row>
                        <Col sm={6}>
                            {filters.payment_system_id && (
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                        <Icon path={mdiDomain} size={1} color="grey" />
                                    </span>
                                </InputGroupAddon>
                                <Input
                                    type="select"
                                    className="custom-select"
                                    onChange={(e) => changed('acquirer_id', e.target.value)}
                                    value={filters.acquirer_id}
                                >
                                    <option key="0" value="">---</option>
                                    {acquirers && acquirers.filter(a => a.payment_system_id === filters.payment_system_id).map((a) =>
                                        <option key={a.id} value={a.id}>{a.name}</option>
                                    )}
                                </Input>
                            </InputGroup>
                            )}
                        </Col>
                        <Col sm={6}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                        <Icon path={mdiCreditCardOutline} size={1} color="grey" />
                                    </span>
                                </InputGroupAddon>
                                <Input
                                    type="select"
                                    className="custom-select"
                                    onChange={(e) => changed('card_type_id', e.target.value)}
                                    value={filters.card_type_id}
                                >
                                    <option key="0" value="">---</option>
                                    {card_types && card_types.map((c) =>
                                        <option key={c.id} value={c.id}>{c.name}</option>
                                    )}
                                </Input>
                            </InputGroup>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={4} lg={3}>
                    <Row>
                        <Col sm={6}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                        <Icon path={mdiCashMultiple} size={1} color="grey" />
                                    </span>
                                </InputGroupAddon>
                                <Input
                                    type="select"
                                    className="custom-select"
                                    onChange={(e) => changed('payment_status', e.target.value)}
                                    value={filters.payment_status}
                                >
                                    <option key="all" value="">---</option>
                                    {Transaction.payment_status().map((status) =>
                                        <option key={status} value={status}>
                                            { t(`transactions.payment_statuses.${status}`) }
                                        </option>
                                    )}
                                </Input>
                            </InputGroup>
                        </Col>
                        { (filters.account_id || !user.isSuperAdmin()) && (filters.payment_status === 'paid' || filters.payment_status === 'pending_payment') &&
                        <Col sm={6}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                        <Icon path={mdiCashMultiple} size={1} color="grey" />
                                    </span>
                                </InputGroupAddon>
                                <Input
                                    type="select"
                                    className="custom-select"
                                    onChange={(e) => changed('payment_id', e.target.value)}
                                    value={filters.payment_id}
                                >
                                    <option key="0" value="">---</option>
                                    {payments && payments
                                        .filter(p => (p.account_id === filters.account_id || (!user.isSuperAdmin() && p.account_id === user.account_id)))
                                        .sort((a, b) => (a.payment_date > b.payment_date) ? -1 : ((b.payment_date > a.payment_date) ? 1 : 0))
                                        .map((p) =>
                                        <option key={p.id} value={p.id}>
                                            { moment(p.payment_date).format('LLL') } - {' '}
                                            { Report.format_money(p.amount, p.currency) }
                                        </option>
                                    )}
                                </Input>
                            </InputGroup>
                        </Col>
                        }
                    </Row>
                </Col>
            </Row>
            {!_.isEqual(filters, values) && (
            <Row className="btn-row">
                <Col sm="12" md={{ size: 4, offset: 4 }}>
                    <Button type="button" block color="info" onClick={submit}>Valider</Button>
                </Col>
            </Row>
            )}
        </Form>
    );
}

FiltersForm = reduxForm({
    form: 'filters',
    destroyOnUnmount: false
})(FiltersForm)

FiltersForm = connect((state) => {
    return {
        initialValues: {
            ...state.filters.values,
            from: state.filters.from || moment().startOf('day').toISOString(),
            to: state.filters.to || moment().endOf('day').toISOString(),
        }
    };
})(FiltersForm)

export default FiltersForm;
