import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StyledTable from '../StyledTable/StyledTable';
import TransactionModal from '../TransactionModal/TransactionModal';
import Icon from '@mdi/react';
import {
    mdiBriefcaseAccountOutline,
    mdiBank,
    mdiCalendar,
    mdiPassportBiometric,
    mdiCash,
    mdiAccountOutline,
    mdiTimerSand
} from '@mdi/js';

import moment from 'moment';

import './TransactionsTable.css';

const TransactionsTable = ({transactions}) => {
    const { t }               = useTranslation();

    const [currentTransaction, setCurrentTransaction] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const showTransactionDetails = (transaction) => {
        setCurrentTransaction(transaction);
        setShowModal(true);
    };

    const hideTransactionDetails = () => {
        setCurrentTransaction(null);
        setShowModal(false);
    };

    const getHeaderIconPath = (columnName) => {
        switch (columnName) {
            case 'type':
                return mdiCash;
            case 'account':
                return mdiBriefcaseAccountOutline;
            case 'payment_system':
                return mdiBank;
            case 'transaction_date':
                return mdiCalendar;
            case 'device':
                return mdiPassportBiometric;
            case 'gross_amount':
                return mdiCash;
            case 'buyer':
            default:
                return mdiAccountOutline;
        }
    }

    const headerFormatter = (column, colIndex, columnName) =>
        <>
            <Icon path={ getHeaderIconPath(columnName) } size={ 1 } color="white" />
            {' '}
            { column.text }
        </>;

    const cellFormatter = (text, smallText, capitalizeSmallText, hasWarning) =>
        <>
            <div>
                {hasWarning && <Icon path={ mdiTimerSand } size={ 1 } color="#A7191E" />
                }
                { text }
            </div>
            {smallText &&
                <small className={capitalizeSmallText ? 'capitalize': ''}>{ smallText }</small>
            }
        </>

    const columns = [{
        dataField: 'type',
        text: t('transactions.type'),
        classes: (type, row) => `${type} with-left-icon ${row.status} ${(currentTransaction || {}).id === row.id ? 'selected' : ''}`,
        formatter: (type, row) => cellFormatter(
            t(`transactions.types.${type}`),
            t(`transactions.statuses.${row.status}`),
            false,
            !row.completed
        ),
        headerFormatter: (col, index) => headerFormatter(col, index, 'type')
    }, {
        dataField: 'status',
        text: t('transactions.status'),
        hidden: true
    }, {
        dataField: 'account.name',
        text: t('transactions.account'),
        hidden: false,
        classes: (type, row) => 'with-left-icon',
        formatter: (name, row) => cellFormatter(name),
    }, {
        dataField: 'ref',
        text: t('transactions.ref'),
        hidden: true
    }, {
        dataField: 'order_id',
        text: t('transactions.order_id'),
        hidden: true
    }, {
        dataField: 'payment_system.name',
        text: t('transactions.payment_system'),
        sort: true,
        classes: (type, row) => 'with-left-icon',
        formatter: (psp, row) => cellFormatter(psp, row.ref),
        headerFormatter: (col, index) => headerFormatter(col, index, 'payment_system')
    }, {
        dataField: 'acquirer.name',
        text: t('transactions.acquirer'),
        hidden: true
    }, {
        dataField: 'order_date',
        text: t('transactions.order_date'),
        hidden: true,
        csvFormatter: (date, row, rowIndex, formatExtraData) => {
            return moment(date).format('YYYY-MM-DD HH:mm');
        }
    }, {
        dataField: 'transaction_date',
        text: t('transactions.transaction_date'),
        sort: true,
        classes: (type, row) => 'with-left-icon',
        formatter: (transaction_date, row) => cellFormatter(
            moment(transaction_date).format('lll'),
            row.order_id
        ),
        headerFormatter: (col, index) => headerFormatter(col, index, 'transaction_date'),
        csvFormatter: (cell, row, rowIndex, formatExtraData) => {
            return moment(cell).format('YYYY-MM-DD HH:mm');
        }
    }, {
        dataField: 'device.name',
        text: t('transactions.device'),
        sort: true,
        classes: (type, row) => 'with-left-icon',
        formatter: (device, row) => cellFormatter(device, row.card_type.name, true),
        headerFormatter: (col, index) => headerFormatter(col, index, 'device')
    }, {
        dataField: 'device.identifier',
        text: t('devices.identifier'),
        hidden: true
    }, {
        dataField: 'gross_amount',
        text: t('transactions.gross_amount'),
        sort: true,
        csvType: Number,
        hidden: true
    }, {
        dataField: 'effective_amount',
        text: t('transactions.effective_amount'),
        sort: true,
        csvType: Number,
        classes: (type, row) => 'with-left-icon',
        formatter: (amount, row) => cellFormatter(
            ((amount && amount.toFixed(2)) || '-') + ' ' + row.currency,
            ((row.net_amount && row.net_amount.toFixed(2)) || '-') + ' ' + row.currency
        ),
        headerFormatter: (col, index) => headerFormatter(col, index, 'gross_amount')
    }, {
        dataField: 'payment_system_fees',
        text: t('transactions.payment_system_fees'),
        hidden: true
    }, {
        dataField: 'acquirer_fees',
        text: t('transactions.acquirer_fees'),
        hidden: true
    }, {
        dataField: 'net_amount',
        text: t('transactions.net_amount'),
        hidden: true
    }, {
        dataField: 'buyer',
        text: t('transactions.buyer'),
        sort: true,
        classes: (type, row) => 'with-left-icon',
        formatter: (buyer, row) => cellFormatter(buyer, row.description),
        headerFormatter: (col, index) => headerFormatter(col, index, 'buyer')
    }, {
        dataField: 'description',
        text: t('transactions.description'),
        hidden: true,
        csvFormatter: (description) => description ? description : ""
    }, {
        dataField: 'card_type.name',
        text: t('transactions.card_type'),
        hidden: true,
    }, {
        dataField: 'card_number',
        text: t('transactions.card_number'),
        hidden: true,
        csvFormatter: (card) => card ? card : ""
    }, {
        dataField: 'payment_id',
        text: t('transactions.payment_id'),
        hidden: true,
        csvFormatter: (id, row, rowIndex, formatExtraData) => {
            return id ?? '';
        }
    }, {
        dataField: 'payment_date',
        text: t('transactions.payment_date'),
        hidden: true,
        csvFormatter: (date, row, rowIndex, formatExtraData) => {
            return date ? moment(date).format('YYYY-MM-DD') : "";
        }
    }, {
        dataField: 'payment.iban',
        text: t('transactions.iban'),
        hidden: true,
        csvFormatter: (iban) => iban ? iban : ""
    }];

    const defaultSorted = [{
        dataField: 'transaction_date',
        order: 'desc'
    }];

    const rowClasses = (row, rowIndex) => {
        return (currentTransaction || {}).id === row.id ? 'selected' : null;
    };

    const rowEvents = {
        onClick: (e, transaction, rowIndex) => {
            showTransactionDetails(transaction);
        }
    };
    return (
        <div className="TransactionsTable">
            {currentTransaction &&
            <TransactionModal
                show={ showModal }
                toggle={ toggleModal }
                transaction_id={ currentTransaction.id }
                onClose={ () => { hideTransactionDetails(); }}
            />
            }
            <StyledTable
                data={ transactions }
                columns={ columns }
                defaultSorted={ defaultSorted }
                rowEvents={ rowEvents }
                rowClasses={ rowClasses }
            />
        </div>
    );
}

export default TransactionsTable;
