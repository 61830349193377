import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Loader from '../../../components/Loader/Loader';
import Widget from '../../../components/Widget/Widget';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { BarChart } from '../../../components/Chart';
import ReportResultType from './ReportResultType/ReportResultType';
import ReportResultResume from './ReportResultResume/ReportResultResume';
import { Report } from '../../../models';
import Icon from '@mdi/react';
import {
    mdiCreditCardOutline,
    mdiDomain,
    mdiPassportBiometric,
    mdiBank,
    mdiCashMultiple,
    mdiCashRefund
} from '@mdi/js';
import { Row, Col } from 'reactstrap';

import './ReportResult.css';

const ReportResult = ({ report }) => {
    const { t }    = useTranslation();
    const { user } = useSelector(state => state.auth);

    if (!report)
        return <Loader />;

    const { stats, currency } = report;

    const formatStats      = stats => Object.keys(stats).map(key => ({ ...stats[key], type: key }));
    const formatToBarChart = stats => Object.keys(stats).map(key => (
        { id: key, [t('reports.revenues')]: stats[key].total.effective, [t('reports.fees')]: (stats[key].total.effective - stats[key].total.net) })
    );

    const sm_size = user.isSuperAdmin() ? 6 : 4;
    const md_size = user.isSuperAdmin() ? 3 : 4;

    return (
        <div className="ReportResult">
            <Row>
                <Col sm={sm_size} md={md_size}>
                    <Widget
                        title={ t('reports.nb_transactions') }
                        value={ Report.format_amount(stats.resume.nb) }
                    />
                </Col>
                <Col sm={sm_size} md={md_size}>
                    <Widget
                        title={ t('reports.gross_revenue') }
                        value={ Report.format_money(stats.resume.total.effective, currency) }
                    />
                </Col>
                <Col sm={sm_size} md={md_size}>
                    <Widget
                        title={ t('reports.net_revenue') }
                        value={ Report.format_money(stats.resume.total.net, currency) }
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={sm_size} md={md_size}>
                    <PageTitle
                        title={ t('reports.payments') }
                        icon={ <Icon path={ mdiCashMultiple } color="white" /> }
                        headingSize="3"
                    />
                    <ReportResultResume stats={ stats.payments } currency={ currency } />
                </Col>
                <Col sm={sm_size} md={md_size}>
                    <PageTitle
                        title={ t('reports.refunds') }
                        icon={ <Icon path={ mdiCashRefund } color="white" /> }
                        headingSize="3"
                    />
                    <ReportResultResume stats={ stats.refunds } currency={ currency } />
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <PageTitle
                        title={ t('reports.card_types') }
                        icon={ <Icon path={ mdiCreditCardOutline } color="white" /> }
                        headingSize="2"
                    />
                    <Card className="reportCard">
                        <CardBody>
                            <Row>
                                <Col sm="12" md="6">
                                    <ReportResultType stats={ formatStats(stats.card_types) } currency={ currency } />
                                </Col>
                                <Col sm="12" md="6">
                                    <BarChart
                                        data={ formatToBarChart(stats.card_types) }
                                        keys={ [t('reports.revenues'), t('reports.fees')] }
                                        axisBottomTickRotation={ 45 }
                                        axisLeftLegend={ t('reports.amount') }
                                        labelFormat={ value => Report.format_money(value, currency) }
                                        tooltipFormat={ value => Report.format_money(value, currency) }
                                        enableLabel={ false }
                                        theme={{background:'transparent'}}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12">
                    <PageTitle
                        title={ t('reports.acquirers') }
                        icon={ <Icon path={ mdiDomain } color="white" /> }
                        headingSize="2"
                    />
                    <Card className="reportCard">
                        <CardBody>
                            <Row>
                                <Col sm="12" md="6">
                                    <ReportResultType stats={ formatStats(stats.acquirers) } currency={ currency } />
                                </Col>
                                <Col sm="12" md="6" style={{ height: '500px' }}>
                                    <BarChart
                                        data={ formatToBarChart(stats.acquirers) }
                                        keys={ [t('reports.revenues'), t('reports.fees')] }
                                        axisLeftLegend={ t('reports.amount') }
                                        tooltipFormat={ value => Report.format_money(value, currency) }
                                        enableLabel={ false }
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12">
                    <PageTitle
                        title={ t('reports.payment_systems') }
                        icon={ <Icon path={ mdiBank } color="white" /> }
                        headingSize="2"
                    />
                    <Card className="reportCard">
                        <CardBody>
                            <Row>
                                <Col sm="12" md="6">
                                    <ReportResultType stats={ formatStats(stats.payment_systems) } currency={ currency } />
                                </Col>
                                <Col sm="12" md="6" style={{ height: '500px' }}>
                                    <BarChart
                                        data={ formatToBarChart(stats.payment_systems) }
                                        keys={ [t('reports.revenues'), t('reports.fees')] }
                                        axisLeftLegend={ t('reports.amount') }
                                        labelFormat={ value => Report.format_money(value, currency) }
                                        tooltipFormat={ value => Report.format_money(value, currency) }
                                        enableLabel={ false }
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12">
                    <PageTitle
                        title={ t('reports.devices') }
                        icon={ <Icon path={ mdiPassportBiometric } color="white" /> }
                        headingSize="2"
                    />
                    <Card className="reportCard">
                        <CardBody>
                            <Row>
                                <Col sm="12" md="6">
                                    <ReportResultType stats={ formatStats(stats.devices) } currency={ currency } />
                                </Col>
                                <Col sm="12" md="6" style={{ height: '500px' }}>
                                    <BarChart
                                        data={ formatToBarChart(stats.devices) }
                                        keys={ [t('reports.revenues'), t('reports.fees')] }
                                        axisBottomTickRotation={ 45 }
                                        axisLeftLegend={ t('reports.amount') }
                                        tooltipFormat={ value => Report.format_money(value, currency) }
                                        enableLabel={ false }
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ReportResult;
