import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mdiAlert, mdiCashPlus, mdiArrowLeftCircle } from '@mdi/js';
import { useRouteMatch, Link, Redirect } from 'react-router-dom';
import { Badge, Button, Row, Col } from 'reactstrap';
import Icon from '@mdi/react';
import PageTitle from '../../../components/PageTitle/PageTitle';
import TransactionForm from '../TransactionForm/TransactionForm';
import { createTransaction, startCreateTransaction } from '../../../redux/modules/transactions';

const TransactionCreate = () => {
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const { t }    = useTranslation();

    const {
        transaction,
        transactionSuccessfullyCreated,
        hasLoadingOneError,
        loadingErrorMessage
    } = useSelector(state => state.transactions);

    useEffect(() => {
        dispatch(startCreateTransaction());
    }, [dispatch]);

    if (transactionSuccessfullyCreated) {
        return <Redirect to={ "/transactions" } />
    }

    const actions = <Link to={ path.replace('new', '') }>
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    const handleTransactionCreation = (values) => {
        dispatch(createTransaction(values));
    };

    return (
        <div className="TransactionCreate">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('transactions.add_title') }
                        icon={ <Icon path={mdiCashPlus} color="white" /> }
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={{ size: 4, offset: 4 }} md="6">
                    { hasLoadingOneError && (
                        <div className="alert alert-danger" block>
                            <h3>
                                <Icon path={mdiAlert} color="#721c24" size={ 1 }/>
                                <small>{ loadingErrorMessage }</small>
                            </h3>
                        </div>
                    )}
                    <TransactionForm onSubmit={ handleTransactionCreation } isCreation={ true } />
                </Col>
            </Row>
        </div>
    );
}

export default TransactionCreate;
