import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { logout, updateUser } from '../../../redux/modules/auth';
import Role from '../../../components/Role/Role';
import { useTranslation } from 'react-i18next';
import Menu from './Menu/Menu';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';

import Icon from '@mdi/react';
import { mdiAccount, mdiLogout } from '@mdi/js';
import logo  from '../../../assets/images/Pixoo.png';
import './TopBar.css';

let hasChangedAccount = false;
let TopBar = () => {
    const user                = useSelector(state => state.auth.user);
    const [isOpen, setIsOpen] = useState(false);
    const toggle              = () => setIsOpen(!isOpen);
    const accounts            = user.user_accounts;
    const dispatch            = useDispatch();
    const { t }               = useTranslation();

    const handleLogout = () => {
        dispatch(logout());
    };

    useEffect(() => {
        if (hasChangedAccount)
            window.location.reload();
    }, [user]);

    const changedAccount = (values) => {
        hasChangedAccount = true;
        const accountId = values.target.value;
        dispatch(updateUser({
            ...user,
            account_id: accountId
        }));
    }

    return (
        <div className="TopBar">
            <Navbar color="dark" dark fixed="top" expand="md">
                <NavbarBrand tag={ Link } to="/">
                    <img src={logo} alt="Pixoo" />
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Menu />
                    <Nav className="ml-auto" navbar>
                        <Role not has="super_admin">
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>{ user.current_account.name }</DropdownToggle>
                                <DropdownMenu right>
                                { (accounts || []).map(account =>
                                    ( account.id !== user.account_id && (
                                        <DropdownItem onClick={changedAccount} key={ account.id } value={ account.id }>
                                            { account.name }
                                        </DropdownItem>
                                    ))
                                )}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Role>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>{user.fullname}</DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem>
                                    <NavLink to={`/users/edit/${user.id}`}>
                                        <Icon path={mdiAccount} size={1} color="black" />{ t('topbar.profil_btn_text') }
                                    </NavLink>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={handleLogout}>
                                    <Icon path={mdiLogout} size={1} color="black" />{ t('topbar.logout_btn_text') }
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
}

export default TopBar;
